
.register-container {
	position: absolute;
		top: 12.5px;
		left: 50%; // a enlever
	z-index: 1;
	transform: translate(-50%, 0); // a enlever
	padding: 0px;

	.top-menu {
		display: flex;
		justify-content: flex-end;
		align-items: center; // a enlever ?

		.register-btn {
			.register {
				position: relative;
					top: 0;
					right: 0;
				z-index: 2;
				padding: 0 15px;
				height: 20px;
				background-color: $color-background-light;
			
				@extend %title;
				margin: 0;
				color: $color-text-tertiary;
				font-size: fs(16px);
				transition: color 0.2s ease;
			
				&:hover {
					color: darken($color-accent-primary, 10%);
					text-decoration: none;
				}
			}
		}
	
		.main-socials {
			position: relative;
				top: 0;
				right: 0;
			z-index: 10;

			color: $color-accent-primary;
			background-color: $color-background-light;
			display: flex;
			align-items: center;
			padding: 0 15px;
			margin-left: 20px;
			
			.follow-label {
				text-transform: uppercase;
				font-family: $font-family-title;
				font-weight: $font-weight-light;
				color: $color-accent-primary;
				line-height: 1;
				margin-right: 15px;


				position: relative;
				top: 3px;
			}
		
			a {
				color: $color-accent-primary;
				// font-size: fs(14px);
		
				&:before {
					position: relative;
				}
		
				&:hover {
					color: darken($color-accent-primary, 10%);
					text-decoration: none;
				}
		
				&:focus {
					text-decoration: none;
				}
			}
		}
	}

	@include at-most($screen-sm-max) {
		display: none;
	}
}

.main-menu {
	position: absolute;
		top: 50px;
		left: 0;
	
	width: 100%;
	z-index: 10;
	transform: translateZ(0);
	// overflow: hidden;

	.menu-btn {
		display: none;
	}

	.nav-container {
		position: static;

		&:before {
			content: '';
			position: absolute;
				top: 0;
				left: 60px;

			width: calc(100% - 120px);
			height: 100%;
			background: $white;
			transform-origin: center center;
			transform: scale(0.5, 1);
			z-index: -1;

			transition: transform 0.6s $easeInOutCubic 0.9s;

			// @include at-most($screen-md-max) {
			// 	left: 25px;
			// 	width: calc(100vw - 50px);
			// }
		}
	}

	nav {
		background: $color-background-light;
		margin-left: -$grid-gutter-width/2;
		margin-right: -$grid-gutter-width/2;

		padding: 20px 40px;

		display: flex;
		align-items: center;
		justify-content: space-between;

		&:after {
			content: "";
			position: absolute;
				top: 0px;
				left: 15%;
			z-index: -1;

			width: 70%;
			height: 50%;
			border-radius: 35%;

			box-shadow: 0 0 20px rgba(0,0,0,0.2);
		}

		.logo {
			img {
				height: 60px;
				width: auto;
			}
		}
	}

	ul {
		list-style-type: none;
		padding-left: 0;
		margin: 0;
		margin-left: auto;

		> li {
			display: inline-block;
			vertical-align: middle;

			&.current-menu-item, &.active {
				a, i {
					color: $color-accent-primary;
				}

				i {
					&:before {
						transform: rotate(180deg);
					}
				}
			}

			&.active {
				ul {
					pointer-events: auto;

					&:after {
						opacity: 1;

						transition: opacity 0s $easeInOutCubic 0.9s;
					}
				}
			}

			&.socials {
				.follow {
					padding: 25px 0;

					.follow-label, .socials {
						display: inline-block;
					}

					.follow-label {
						text-transform: uppercase;
						letter-spacing: letter-spacing(200);
						font-weight: $font-weight-bold;
						color: $color-text-primary;
		
						font-size: fs(14px);
						margin-right: 10px;
					}
				}
			}

			& + li {
				margin-left: 15px;
			}

			a {
				text-transform: uppercase;
				letter-spacing: letter-spacing(200);
				font-weight: $font-weight-bold;
				color: $color-text-primary;

				font-size: fs(14px);

				i {
					&:before {
						margin: 0px;
	
						transform: rotate(0);
						transition: transform 0.3s ease;
					}
				}

				&:hover {
					text-decoration: none;
					color: $color-accent-primary;
					
					i {
						color: $color-accent-primary;
					}
				}

				&:active, &:focus {
					text-decoration: none;
				}
			}

			ul {
				pointer-events: none;
				position: relative;
				list-style-type: none;
				padding-left: 0;
				margin: 0;

				width: calc(100% - 120px);

				position: absolute;
					bottom: 1px;
					left: 60px;
					padding: 60px 0 30px;
				
				transform: translate(0, 100%);

				&:after {
					content: "";
					position: absolute;
						bottom: 0px;
						left: 15%;
						right: 15%;
					z-index: -1;
		
					width: 70%;
					height: 20px;
					border-radius: 35%;
		
					box-shadow: 0 0 20px rgba(0,0,0,0.2);
					opacity: 0;

					transition: opacity 0s $easeInOutCubic;
				}
				
				&:before {
					content: '';
					position: absolute;
						top: 0;
						left: 0;
					
					width: 100%;
					height: 100%;
					
					transform-origin: top center;
					transform: scale(1, 0);
					transition: transform 0.6s $easeInOutCubic 0.3s;
					background: $white;
				}
				
				.product-menu-item {
					float: none;
					width: 25%;
					flex: 0 0 auto;
					display: flex;
					justify-content: center;
					flex-direction: column;
					opacity: 0;
					transition: opacity 0.3s ease 0s;
					margin-bottom: 30px;

					img {
						flex: 0 0 auto;
					}
					
					.content {
						cursor: pointer;
						position: relative;
						z-index: 1;
						padding: 25px;
						flex: 1 1 auto;
						transition: color 0.3s $easeInOutCubic;

						&:after {
							content: '';
							position: absolute;
								top:0;
								left: 0;
							z-index: -1;
							width: 100%;
							height: 100%;
							background: $color-accent-primary;
							opacity: 0;
					
							transform-origin: top left;
							transform: scale(0, 1);
							transition: transform 0.3s $easeInOutCubic, opacity 0.3s $easeInOutCubic;
						}
					}

					.title {
						@extend %title;
					}

					.button-simple {
						border-color: $color-accent-primary;
						color: $color-accent-primary;

						width: 65%;
						margin: 0 auto;

						&:hover {
							background: $color-accent-primary;
							color: $white;
						}
					}

					&:hover {
						.content {
							color: $white;
							&:after {
								opacity: 1;
								transform: scale(1, 1);
								transition: transform 0.3s $easeInOutCubic, opacity 0.3s $easeInOutCubic;
							}
						}
					}

					&.last-item {
						flex: 1 1 auto;
					}
				}

				.overlay-link {
					position: absolute;
						top: 0;
						left: 0;
					z-index: 2;

					width: 100%;
					height: 100%;
				}

				.flex-row {
					display: flex;
					flex-wrap: wrap;
				}
			}
		}
	}

	&.opened {
		.nav-container:before {
			transform: scale(1, 1);
			transition: transform 0.6s $easeInOutCubic;
		}
		
		ul > li.active ul {
			&:before {
				transform: scale(1, 1);
				transition: transform 0.3s $easeInOutCubic 0.6s;
			}
			
			.product-menu-item {
				opacity: 1;
				
				@for $i from 1 through 10 {
					&:nth-child(#{$i}) {
						$delay: 0.8s + (0.1s * $i);
						transition: opacity 0.6s ease $delay;
					}
				}
			}
		}
	}

	&.overflow {
		overflow: visible
	}

	.mobile-only {
		display: none;
	}

	
	.footer-only {
		display: none;
	}

	// @include at-most($screen-md-max) {
	// 	top: 50px;
		
	// 	.nav-container:before {
	// 		width: calc(100% - 50px);
	// 	}
		
	// 	ul > li ul {
	// 		width: calc(100% - 50px);
	// 		left: 25px;
	// 	}

	// 	.register {
	// 		line-height: 1.4;
	// 		text-align: center;
	// 	}
	// }

	// @include at-most($screen-sm-max) {
	// 	nav {
	// 		padding: 20px 25px;
	// 	}

	// 	.register {
	// 		font-size: fs(12px);
	// 		max-width: 100px;
	// 		text-align: center;
	// 		line-height: 1.4;
	// 	}

	// 	.logo {
	// 		max-width: 80px;
	// 	}

	// 	ul {
	// 		line-height: calc(1em - 4px);

	// 		> li {
	// 			line-height: 1;

	// 			a {
	// 				font-size: fs(14px);
	// 			}

	// 			& + li {
	// 				margin-left: 10px;
	// 			}
	// 		}
	// 	}
	// }

	// Mobile menu
	@include at-most($screen-sm-max) {
		overflow: visible;

		.menu-btn {
			display: block;
			width: 40px;
			height: 40px;

			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 1;

			span, span:before, span:after {
				display: block;
				width: 25px;
				height: 3px;
				background: $color-accent-primary;
			}

			span {
				position: relative;
				transition: background 0.3s ease;

				&:before, &:after {
					position: absolute;
					content: '';
					transition: transform 0.3s ease;
				}

				&:before {
					bottom: 8px;
				}

				&:after {
					top: 8px;
				}
			}
		}

		.nav-container {
			&:before {
				width: 100%;
				height: 100%;
				left: 0;
				top: auto;
				bottom: 0;

				transform-origin: center bottom;
				transition: transform 0.3s $easeInOutCubic 0.8s, height 0.3s $easeInOutCubic 0.5s;
			}
		}

		nav {
			position: relative;
			padding: 15px $grid-gutter-width/2;
			margin: 0;

			> ul {
				position: absolute;
					top: 100%;
					left: calc((100vw - 100%) / -2);

				width: 100vw;
				background: $white;
				transform: scale(1, 0);
				transition: transform 0.4s $easeInOutCubic 0.3s;
				transform-origin: top center;

				> .menu-item {
					opacity: 0;
					transition: opacity 0.3s ease;

					> a {
						background: $white;
						border-bottom: 1px solid $gray93;
					}

					&:last-child > a {
						border-bottom: none;
					}
				}
				
				.menu-item {
					display: block;
					text-align: center;
					padding: 0 25px;

					> a {
						display: block;
						padding: 25px 0;
					}
					
					& + li {
						margin-left: 0;
					}

					&.footer-only {
						display: none;
					}
				}

				.submenu {
					position: relative;
						top: auto;
						left: auto;

					transform: none;
					width: 100%;
					padding: 30px 0;
					border-bottom: 1px solid $gray93;
					display: none;
					box-shadow: none;

					.flex-row {
						display: block;
					}

					.product-menu-item {
						width: 100%;
						opacity: 1;
						margin-bottom: 0;

						.title {
							margin-bottom: 0;
						}

						.content {
							padding: 15px 0;
						}

						img {
							display: none;
						}

						span {
							display: none;
						}

						.button-simple {
							width: 100%;
							padding: 15px;
							margin: 15px 0;
						}
					}

				}
			}

			.register {
				display: none;
			}
		}

		&.opened {
			.nav-container:before {
				height: calc(100% + 15px);
				transform: scale(1, 1);
				transition: transform 0.3s $easeInOutCubic, height 0.3s $easeInOutCubic 0.3s;
			}

			nav > ul {
				transform: scale(1, 1);
				transition: transform 0.4s $easeInOutCubic 0.4s;

				> .menu-item {
					opacity: 1;

					@for $i from 1 through 10 {
						&:nth-child(#{$i}) {
							$delay: 0.6s + (0.05s * $i);
							transition: opacity 0.3s ease $delay;
						}
					}
				}
			}
			
			ul > li ul {
				&:before {
					transform: none;
				}
			}

			.menu-btn {
				span {
					background: rgba($white, 0);

					&:before {
						transform: translate(0, 8px) rotate(225deg);
					}
					&:after {
						transform: translate(0, -8px) rotate(-225deg);
					}
				}
			}
		}
	}

	@include at-most($screen-sm-max) {
		top: 15px;
	}

	// @include at-most($screen-xs-max) {
	// 	top: 15px;
	// }
}