@import url('../assets/fontello/css/fontello-codes.css');

@font-face {
  font-family: 'fontello';
  src: url('../assets/fontello/font/fontello.eot');
  src: url('../assets/fontello/font/fontello.eot#iefix') format('embedded-opentype'),
       url('../assets/fontello/font/fontello.woff2') format('woff2'),
       url('../assets/fontello/font/fontello.woff') format('woff'),
       url('../assets/fontello/font/fontello.ttf') format('truetype'),
       url('../assets/fontello/font/fontello.svg#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
	font-family: 'Century Gothic';
	src: url('../assets/font/CenturyGothic-BoldItalic.eot');
	src: url('../assets/font/CenturyGothic-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../assets/font/CenturyGothic-BoldItalic.woff') format('woff'),
		url('../assets/font/CenturyGothic-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Century Gothic';
	src: url('../assets/font/CenturyGothic.eot');
	src: url('../assets/font/CenturyGothic.eot?#iefix') format('embedded-opentype'),
		url('../assets/font/CenturyGothic.woff') format('woff'),
		url('../assets/font/CenturyGothic.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Century Gothic';
	src: url('../assets/font/CenturyGothic-Bold.eot');
	src: url('../assets/font/CenturyGothic-Bold.eot?#iefix') format('embedded-opentype'),
		url('../assets/font/CenturyGothic-Bold.woff') format('woff'),
		url('../assets/font/CenturyGothic-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Century Gothic';
	src: url('../assets/font/CenturyGothic-Italic.eot');
	src: url('../assets/font/CenturyGothic-Italic.eot?#iefix') format('embedded-opentype'),
		url('../assets/font/CenturyGothic-Italic.woff') format('woff'),
		url('../assets/font/CenturyGothic-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Clarendon LT Std';
	src: url('../assets/font/ClarendonLTStd.eot');
	src: url('../assets/font/ClarendonLTStd.eot?#iefix') format('embedded-opentype'),
		url('../assets/font/ClarendonLTStd.woff') format('woff'),
		url('../assets/font/ClarendonLTStd.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Clarendon LT Std';
	src: url('../assets/font/ClarendonLTStd-Light.eot');
	src: url('../assets/font/ClarendonLTStd-Light.eot?#iefix') format('embedded-opentype'),
		url('../assets/font/ClarendonLTStd-Light.woff') format('woff'),
		url('../assets/font/ClarendonLTStd-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Clarendon LT Std';
	src: url('../assets/font/ClarendonLTStd-Bold.eot');
	src: url('../assets/font/ClarendonLTStd-Bold.eot?#iefix') format('embedded-opentype'),
		url('../assets/font/ClarendonLTStd-Bold.woff') format('woff'),
		url('../assets/font/ClarendonLTStd-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@mixin fontello() {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
[class^="icon-"]:before, [class*=" icon-"]:before {
  @include fontello();
}

