.blog-nav {
	position: relative;
	z-index: 1;
	background: $color-background-light;
	margin-top: -100px;
}

.blog-list {
	padding: 50px 0;
	overflow: hidden;

	.featured-articles {
		margin-bottom: 90px;
		position: relative;

		&:before {
			content: '';
			z-index: 1;
			position: absolute;
				top: -40px;
				left: -1/12 * 100%;

			height: calc(100% + 80px);
			width: calc(11/12 * 100%);
			border: 2px solid $color-accent-primary_light;
		}

		.row {
			display: flex;

			> div {
				display: flex;
				flex-direction: column;
			}
		}

		.featured-post {
			position: relative;
			z-index: 2;
			flex: 1 1 auto;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			
			padding: 50px 30px;

			background: center center no-repeat;
			background-size: cover;
			transform: translateZ(0);
			@extend %inverted_colors;

			position: relative;

			&:before {
				content: '';
				position: absolute;
					top: 0;
					left: 0;

				width: 100%;
				height: 100%;
				background: rgba($gray21, 0.65);
			}

			.overlay-link {
				position: absolute;
					top: 0;
					left: 0;

				width: 100%;
				height: 100%;
				cursor: pointer;
				outline: none;

				z-index: 3;

				&:hover {
					~ .content {
						[class^=button] {
							@extend %button_white_hover;
						}
						.button-simple {
							background-color: $color-background-main;
							color: $color-text-primary;
						}
					}

				}
			}
		
			.content {
				width: 100%;
				position: relative;
				z-index: 2;

				[class^=button] {
					justify-content: center;
				}
			}

			.button-simple {
				display: inline-block;
				width: 3/8 * 100%;
				margin-top: 30px;
			}

			&.big {
				.content {
					max-width: 50%;
				}
	
				.button-simple {
					width: 3/8 * 200%;
				}
			}

			.category {
				margin-bottom: 30px;
			}

			h1 {
				font-family: $font-family-title;
				font-weight: $font-weight-light;
				font-size: fs(45px);
			}


			.button-more-white {
				span {
					margin-right: 0;
					margin-left: 10px;
				}
			}

			.category {
				text-transform: uppercase;
				font-weight: $font-weight-bold;
				letter-spacing: letter-spacing(200);
				font-size: fs(14px);
			}

			& + .featured-post {
				margin-top: $grid-gutter-width;
			}
		}

		@include at-most($screen-lg-max) {
			.featured-post {
				h1 {
					font-size: fs(40px);
				}
			}
		}

		@include at-most($screen-md-max) {
			.featured-post {
				h1 {
					font-size: fs(30px);
				}
			}
		}
	}
	
	.latest-articles {
		h1 {
			@extend %title3;
			font-weight: $font-weight-light;
			font-family: $font-family-title;
		}
		
		.post-preview {
			height: 100%;
			display: flex;
			flex-direction: column;
			margin-bottom: $grid-gutter-width;
	
			.post-content {
				background: $color-background-main;
				flex: 1 1 auto;
	
				display: flex;
				flex-direction: column;
	
				.btns-row {
					margin-top: auto;

					a {
						justify-content: flex-end;
					}
				}
			}

			.overlay-link {
				&:hover {
					~ .post-content .btns-row [class^=button] {
						@extend %button_gold_hover;
					}
				}
			}
		}
	
		.flex-row {
			display: flex;
			flex-wrap: wrap;
		}
	
		.pagination {
			margin: 80px 0;
			text-align: center;
	
			.pages {
				list-style-type: none;
				padding-left: 0;
				margin: 0;
	
				display: flex;
				justify-content: center;
				align-items: center;
	
				li {
					& + li {
						margin-left: 25px;
					}
				}
	
				a, span {
					color: $color-text-primary;
					font-weight: $font-weight-bold;
	
					i {
						font-size: fs(20px);
					}
	
					&.current {
						color: $color-accent-primary;
					}
	
					&:hover {
						text-decoration: none;
						color: $color-accent-primary;
					}
				}
			}
		}
	}

	@include at-most($screen-sm-max) {
		.featured-articles {
			.row {
				display: block;
				justify-content: space-between;

				> div {
					flex-direction: row;
				}
			}

			.featured-post {
				flex: 0 0 auto;
				width: calc(50% - #{$grid-gutter-width / 2});

				&.big {
					flex: 1 1 auto;
					margin-bottom: 30px;
				}

				& + .featured-post {
					margin-top: 0;
					margin-left: $grid-gutter-width;
				}
			}
		}

		.latest-articles {
			.post-container {
				margin-bottom: 30px;
			}

			.pagination {
				padding: 50px 0 0;
				margin: 0;
			}
		}
	}

	@include at-most($screen-xs-max) {
		padding: 30/320 * 100% 0;
		
		.latest-articles {
			.post-container {
				margin-bottom: 30px;

				&:last-child() {
					margin-bottom: 0px;
				}
			}
		}

		.featured-articles {
			margin-bottom: 50px;

			&:before {
				content: none;
			}

			.row > div {
				flex-wrap: wrap;
			}

			.featured-post {
				padding: 30px $grid-gutter-width/2;
				width: 100%;

				&.big {
					.content {
						max-width: none;
					}
				}

				& + .featured-post {
					margin-left: 0;
					margin-top: 30px;
				}
			}
		}
	}
}
