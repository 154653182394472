
.product-list {
	background: $color-background-light;
	padding: 80px 0 230/1920 * 100vw;
	position: relative;
	z-index: 2;

	.flex-row {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		&:after {
			content: '';
			flex: 0 0 auto;
			width: calc(100% / 3 - #{$grid-gutter-width * 2 / 3});
		}
	}

	&:after {
		position: absolute;
			bottom: 0;
			left: 0;

		content: '';
		width: 100%;
		height: 170/1920 * 100vw;
		background: url(../img/beans-bg.jpg) center center no-repeat;
		background-size: cover;
	}

	@include at-most($screen-xs-max) {
		padding: 20/320 * 100% 0 230/1920 * 100vw;
	}
}

.product-list-item {
	position: relative;

	width: calc(100% / 3 - #{$grid-gutter-width * 2 / 3});
	flex: 0 0 auto;

	display: flex;
	flex-direction: column;

	img {
		flex: 0 0 auto;
	}

	.overlay-link {
		position: absolute;
			top: 0;
			left: 0;

		width: 100%;
		height: 100%;
		z-index: 2;
	}

	.infos {
		position: relative;
		flex: 1 1 auto;
		padding: 40px;
		background: transparent;
		transition: color 0.3s;

		h1 {
			font-size: fs(24px);
		}

		h2 {
			font-size: fs(18px);
			color: $color-accent-primary;
		}

		span {
			color: $color-accent-primary;
		}


		&:after {
			content: '';
			position: absolute;
				top:0;
				left: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			background: $color-accent-primary;
			opacity: 0;
	
			transform-origin: top left;
			transform: scale(0, 1);
			transition: transform 0.3s $easeInOutCubic, opacity 0.3s $easeInOutCubic;
		}
	}

	&:hover {
		.infos {
			color: $white;

			&:after {
				opacity: 1;
				transform: scale(1, 1);
				transition: transform 0.3s $easeInOutCubic, opacity 0.3s $easeInOutCubic;
			}

			&, h2, span {
				@extend %inverted_colors;
			}
		}
	}

	&:nth-last-child(n+3) {
		margin-bottom: 50px;
	}

	@include at-most($screen-sm-max) {
		width: calc(100% / 2 - #{$grid-gutter-width * 1 / 2});
		
		&:nth-last-child(n+3) {
			margin-bottom: 0;
		}	
		
		// &:nth-last-child(n+3) {
		// 	margin-bottom: 80px;
		// }
	}

	@include at-most($screen-xs-max) {
		width: 100%;

		&:hover {
			.infos {	
				&:after {
					content: none; 
				}
			}
		}

		.infos {
			padding: 0 $grid-gutter-width/2 30px;
			text-align: center;
		}
		
		&, &:nth-last-child(n+3) {
			margin-bottom: 20/320 * 100%;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:hover {
			.infos {
				background: transparent;
				color: $color-text-primary;

				h2, span {
					color: $color-accent-primary;
				}
			}
		}
	}
}