

.full-tout {
	margin: 50px;

	.main-content {
		position: relative;
		padding: 180/1920 * 100vw 0;
	
		.container {
			position: relative;
			z-index: 2;
		}
	
		.bg {
			position: absolute;
				top: 0;
				left: 0;
	
			width: 100%;
			height: 100%;
			
			z-index: 1;
	
			background: left center no-repeat;
			background-size: cover;
		}
	
		[class^=button] {
			margin-top: 50px;
			color: $color-accent-primary;
	
			&:hover {
				@extend %button_gold_hover;
			}
		}
	
		h1, h2 {
			margin-bottom: 0.25em;
		}

		h2 {
			@extend %title4;
		}
	
		h2, h3 {
			margin-bottom: 0;
			color: $color-accent-primary;
		}
	}

	.subcontent {
		position: relative;
		padding-top: 120px;

		@include at-most($screen-sm-max) {
			padding-top: 50px;
		}
	}

	.normal-cta {
		position: relative;
		// margin-top: -180px;

		.overlay-link {
			position: absolute;
				top: 0;
				left: 0;
			width: 100%;
			height: 100%;
			z-index: 3;

			&:hover {
				~ .content {
					[class^=button] {
						background: $color-background-main;
						color: $color-text-primary;
					}
				}
			}
		}

		&:after {
			content: '';
			position: absolute;
				top: 45px;
				left: -30px;

			width: calc(100% - #{$grid-gutter-width});
			height: 100%;
			border: 2px solid $color-accent-primary_light;
		}

		.content {
			@extend %inverted_colors;
			padding: 20%;
			text-align: center;
			background: center center no-repeat;
			background-size: cover;

			position: relative;
			z-index: 2;

			[class^=button] {
				margin-top: 40px;
			}

			&:after {
				content: '';
				position: absolute;
					top: 0;
					left: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				display: block;
				background-color: rgba($gray15, 0.65);
			}
		}
	}

	.latest-post {
		position: relative;

		> [class^=button] {
			position: relative;
			z-index: 2;
			color: $color-accent-primary_light;
			border-color: $color-accent-primary_light;
			width: calc(5/6 * 100% - 5px);
			margin-top: 50px;

			&:hover {
				background: $color-accent-primary_light;
				color: $color-text-secondary;
			}
		}

		.post-preview {
			img {
				width: 100%;
			}

			.post-content {
				.btns-row {
					[class^=button] {
						justify-content: flex-end;	
					}
				}
			}

			.overlay-link {
				&:hover {
					~ .post-content .btns-row [class^=button] {
						@extend %button_gold_hover;
					}
				}
			}
		}

		.content {
			position: relative;
			z-index: 2;
			width: calc(5/6 * 100%);
			margin-left: calc(1/6 * 100%);
			margin-top: -30px;

			> h1 {
				max-width: 3/5 * 100%;
			}
		}

		&:after {
			content: '';
			position: absolute;
				top: 0;
				left: $grid-gutter-width/2;

			width: calc(5/6 * 100% - #{$grid-gutter-width});
			height: 100%;
			border: 2px solid $color-accent-primary_light;
		}
	}

	@include at-most($screen-sm-max) {
		margin-bottom: 100px;

		.subcontent {
			.row {
				display: flex;
				flex-direction: column-reverse;
			}
		}

		.latest-post {
			margin-top: 160px;

			.content {
				width: 100%;
				margin-left: 0;
			}

			.button-simple {
				left: calc(-20px - #{$grid-gutter-width / 2});
			}

			&:after {
				top: -50px;
				height: calc(100% + 50px);
				left: -20px;
			}
		}

		.normal-cta {
			margin-top: 60px;

			&:after {
				top: 30px;
				left: -10px;
			}
		}
	}

	@include at-most($screen-xs-max) {
		margin-bottom: 50px;

		.main-content {
			background-color: $color-background-main;
			padding-top: 0;
			padding-bottom: 0;

			.container {
				padding: 10px 30px 30px 30px;
			}

			.bg {
				@include reset-position();
				width: 100%;
				height: 0;
				padding-top: 9/16 * 100%;
				background-position: center center;
				margin-bottom: 20px;
			}
		}

		.normal-cta {
			margin-top: 0px;

			&:after {
				top: 15px;
				left: 0;
			}
		}

		.latest-post {
			margin-top: 130px;

			.button-simple {
				left: -15px;
			}

			&:after {
				left: 0px;
			}
		}
	}
}