
.product-features {
	.hotspot-container {
		position: relative;

		.reference {
			img {
				width: 100%;
			}
		}
	}

	.hotspot {
		position: absolute;

		cursor: pointer;
		// z-index: 1;

		.button-more {
			pointer-events: none;
			
			span {
				z-index: 1;
				margin-right: 0;
				border-color: rgba($color-accent-primary, 0.65);
				
				i {
					font-size: fs(14px);
					transform-origin: center center;
					transform: translate(-50%, -50%) rotate(0);
					transition: transform 0.2s ease;
				}
				
				&:after {
					z-index: 2;
					border-color: rgba($color-accent-primary, 0.5);
					background-color: rgba($gray93, 0.50);
				}
			}
		}
		
		.title, .content {
			display: none;
		}
		
		&.active {
			.button-more {
				span {
					z-index: 1;
					margin-right: 0;
					border-color: rgba($color-accent-primary, 1);
					
					i {
						transform: translate(-50%, -50%) rotate(45deg);
					}

					&:after {
						z-index: 2;
						border-color: rgba($color-accent-primary, 0.6);
						background-color: rgba($gray93, 0.70);
					}
				}
			}
		}

		&:hover {
			.button-more {
				span {
					z-index: 1;
					margin-right: 0;
					border-color: rgba($color-accent-primary, 1);
					
					&:after {
						z-index: 2;
						border-color: rgba($color-accent-primary, 0.6);
						background-color: rgba($gray93, 0.70);
					}
				}
			}
		}
	}

	.product-overlay {
		padding: 30/1920 * 100vw;
		background: rgba($gray93, 0.9);

		position: absolute;
			top: 50%;
			left: 50%;
		z-index: 4;
		
		transform: translate(-50%, 0);
		opacity: 0;
		visibility: hidden;
		width: 400/1920 * 100vw;

		transition: visibility 0s ease 0.3s, opacity 0.3s;

		h1 {
			@extend %title3;
			text-transform: none;
			font-size: fs(16px);
			margin-bottom: 10px;
		}

		p {
			font-size: fs(14px);
		}

		p:last-child {
			margin-bottom: 0;
		}
		
		.title, .content {
			// overflow: hidden;
			
			> * {
				position: relative;
				opacity: 0;
				transform: translate(0, 10px);
				
				transition: opacity 0.3s ease 0.1s, transform 0.3s ease 0.1s;
				
				&:nth-child(2) {					
					transition: opacity 0.3s ease 0.3s, transform 0.3s ease 0.3s;
				}
			}
		}

		&:after {
			position: absolute;
				top: 0;
				left: 50%;
			
			content: '';
			transform: translate(-50%, -100%);

			@include triangle((
				color: rgba($gray93, 0.9),
				width: 8px,
				side: 'top',
			));
		}

		&.overflow {
			overflow: auto;
		}
		
		&.active {
			opacity: 1;
			visibility: visible;

			transition: visibility 0s, opacity 0.3s;
			
			
			.title > *, .content > * {
				opacity: 1;
				transform: translate(0, 0);
			}
		}
		
		@include at-most($screen-md-max) {
			width: 550/1920 * 100vw;
		}
		
		@include at-most($screen-xs-max) {
			display: none;
		}
	}

	@include at-most($screen-xs-max) {
		.hotspot-container {
			&:after {
				visibility: hidden;
				opacity: 0;
				pointer-events: none;

				content: '';
				position: absolute;
					top: 0;
					left: 0;

				z-index: 3;
	
				width: 100%;
				height: 100%;
				background: rgba($black, 0.8);

				transform: visibility 0.3s, opacity 0.3s;
			}

			&.active {
				&:after {
					visibility: visible;
					opacity: 1;
					pointer-events: auto;
				}
			}
		}

		.product-overlay {
			&:after {
				display: none;
			}
		}
	}
}

.features {
	background-color: $color-background-main;

	.features-list {
		padding: 80px 0;

		display: flex;
		flex-wrap: wrap;

		.feature-item {
			margin-top: 30px;
			margin-bottom: 30px;

			h1 {
				@extend %title3;
				text-transform: none;
				font-size: fs(16px);
				margin-bottom: 10px;
			}

			p {
				margin-bottom: 0px;
			}

			&:before {
				content: '';
				position: absolute;
					top: 4px;
					left: -15px;
				width: 10px;
				height: 10px;
				border-radius: 100%;
				border: 2px solid $color-accent-primary_light;

				@include at-most($screen-xs-max) {
					left: -5px;
				}
			}

			@include at-most($screen-xs-max) {
				width: 100%;
			}
		}

		@include at-most($screen-xs-max) {
			width: 100%;
			padding: 30px 0;
		}
	}
}
