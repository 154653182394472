
.footer-content {
	background-color: $color-background-main;
	padding: 60px 0;


	.title {
		text-transform: uppercase;
		margin-bottom: 20px;
	}

	.contact-infos {
		.contact-title {
			text-transform: uppercase;
			margin-top: 30px;
			margin-bottom: 5px;
		}

		.contact-address {
			font-family: $font-family-title;
			font-weight: $font-weight-light;
			font-size: fs(14px);
	
			color: $color-text-primary;

			a {
				color: $color-accent-primary;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.product-nav {
		ul {
			li {
				line-height: 1;
				position: relative;
				padding-left: 10px;
				
				&:before {
					content: '-';
					position: absolute;
						top: 0;
						left: 0;
				}

				a {
					font-family: $font-family-title;
					font-style: italic;
					font-weight: $font-weight-light;
					font-size: fs(14px);

					color: $color-text-primary;

					&:hover {
						text-decoration: none;
						color: $color-accent-primary;
					}
				}

				& + li {
					margin-top: 15px;
				}
			}
		}
	}

	nav {
		ul {
			padding-left: 0;
			margin: 0;
			list-style-type: none;
		}
	}

	.footer-nav {
		ul {
			li {
				margin-bottom: 15px;
				
				a {
					text-transform: uppercase;
					color: $color-text-primary;
					font-weight: $font-weight-bold;
					line-height: 1px;

					&:hover {
						color: $color-accent-primary;
						text-decoration: none;
					}
				}

				&.emphasis {
					margin-top: 30px;
					a {
						font-weight: $font-weight-light;
						font-family: $font-family-title;
						color: $color-accent-primary;
						
						transition: color 0.3s ease;

						&:hover {
							color: darken($color-accent-primary, 15%);
							text-decoration: none;
						}
					}
				}

				&.header-only {
					display: none;
				}
			}
		}
	}

	.newsletter {
		.title {
			@extend %title3;
			color: $color-accent-primary;
			font-size: fs(20px);
		}

		form {
			margin-bottom: 25px;
			margin-top: 20px;
			position: relative;

			input {
				padding: 15px 50px 10px 15px;
				background-color: $color-background-main;
			}

			button {
				position: absolute;
					top: 1px;
					right: 1px;
				
					height: calc(100% - 2px);
					width: 50px;

				display: flex;
				align-items: center;
				justify-content: center;
				color: $color-accent-primary;
				background: $color-background-main;
				outline: none;
				border: none;
				text-align: center;
				padding: 0;

				transition: color 0.3s, background 0.3s;

				&:hover {
					background: $color-accent-primary;
					color: $color-text-secondary;
				}
			}
		}

		.follow {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-family: $font-family-title;
			text-transform: uppercase;
			font-weight: $font-weight-light;
			color: $color-accent-primary;
			line-height: 1;
			font-size: fs(14px);

			.socials {
				a {
					color: $color-accent-primary;
					font-size: fs(20px);

					&:hover {
						text-decoration: none;
						color: darken($color-accent-primary, 10%);						
					}
					
					&:focus {
						text-decoration: none;
					}
				}
			}

			@include at-most($screen-md-max) {
				flex-direction: column;
				align-items: flex-start;

				span {
					margin-bottom: 8px;
				}
			}
		}
	}

	.products-menu {
		float: left;
		width: nestedWidth(2, 7);
		margin-left: nestedWidth(1, 7);
		padding: 0 $grid-gutter-width/2;
	}

	.other-menu {
		float: left;
		width: nestedWidth(2, 7);
		margin-left: nestedWidth(1, 7);
		padding: 0 $grid-gutter-width/2;
	}

	@include at-most($screen-md-max) {
		.products-menu {
			width: nestedWidth(3, 7);
		}

		.other-menu {
			margin-left: 0;
		}
	}
	
	@include at-most($screen-sm-max) {
		.logo {
			img {
				max-height: 60px;
				width: auto;
			}
		}

		.products-menu {
			width: 50%;
			margin-left: 0;
		}

		.other-menu {
			width: 50%;
		}

		
		.menus-container {
			margin: 50px 0;
		}
	}

	@include at-most($screen-xs-max) {
		padding: 30px 0;

		.menus-container {
			margin: 25px 0;
		}

		.products-menu, .other-menu {
			width: 100%;
		}

		.other-menu {
			margin-top: 20px;
		}
	}
}

.after-footer {
	padding: 30px 0;

	text-transform: uppercase;
	color: $color-accent-primary;
	font-family: $font-family-title;
	font-weight: $font-weight-light;
	font-size: fs(14px);
	background-color: $color-background-light;

	a {
		color: $color-accent-primary;
		text-decoration: underline;
		transition: color 0.3s; 

		&:hover {
			color: lighten($color-accent-primary, 10%);
		}
	}

	.content {
		display: flex;
		align-items: center;

		nav {
			margin-right: 30px;
		}
	}

	@include at-most($screen-xs-max) {
		.content {
			display: block;
			text-align: center;

			nav {
				margin-right: 0;
				margin-top: 10px;
			}
		}
	}
}