.product-shown {
	padding: 50px 0;

	.infos {
		z-index: 2;
	}

	@include at-most($screen-xs-max) {
		.infos {
			padding: 30px $grid-gutter-width/2;
			text-align: center;
		}

		.product-list-item:hover {
			 .infos {
				&:after { 
					content: none;
				}
			}
		}
	}
}