.experience-nav {
	position: absolute;
	bottom: 100%;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 25px;
	margin-bottom: 45px;

	.experience-nav-step {
		position: relative;
		margin-right: 300 / 1920 * 100vw;
		opacity: 0.4;

		&:last-child {
			margin-right: 0;
			.line {
				display: none;
			}
		}

		&.active {
			.circle:before {
				opacity: 1;
			}
		}

		&.on {
			opacity: 1;
		}

		.circle:hover {
			cursor: pointer;
			transform: scale(1.1);
		}
	}

	.circle {
		position: relative;
		width: 30px;
		height: 30px;
		border: 2px solid #bfb3ac;
		border-radius: 100%;
		transition: transform 0.3s ease, opacity 0.3s ease;

		&:before {
			content: '';
			position: absolute;
				top: 50%;
				left: 50%;
			transform: translate(-50%, -50%);
			width: 16px;
			height: 16px;
			border-radius: 100%;
			background: $color-accent-primary;
			opacity: 0;
			transition: opacity 0.3s ease;
		}
	}

	.line {
		position: absolute;
		top: 50%;
		left: 100%;
		width: 300 / 1920 * 100vw;
		height: 1px;
		background: rgba($color-accent-primary, 0.4);

		.inner-line {
			position: absolute;
				top: 0;
				left: 0;
			height: 100%;
			background: $color-accent-primary;
		}
	}

	.label {
		position: absolute;
			top: 100%;
			left: 50%;
		transform: translate(-50%, 0);
		color: $color-accent-primary;
		letter-spacing: 0.2em;
		text-transform: uppercase;
		font-weight: bold;

		@include at-most($screen-xs) {
			font-size: 12px;
		}

	}
}