
.experience-ctas {
	position: relative;
	margin: 50px 0;
	overflow: hidden;
	
	&:before {
		content: '';
		position: absolute;
			top: 0px;
			left: 50%;

		transform: translate(-50%, 0);
		border: 2px solid $color-accent-primary_light;
		height: calc(100% - 180px);
		width: $container-xl / 2;
		
		@include at-most($screen-lg-max) {
			width: $container-lg / 2;
		}

		@include at-most($screen-md-max) {
			width: $container-md / 2;
		}

		@include at-most($screen-xs-max) {
			width: $container-sm / 2;
		}
	}

	.row {
		display: flex;
	}

	.single-cta {
		position: relative;
		z-index: 2;
		margin-top: 50px;

		h1 {
			margin-bottom: 0;
			color: $color-text-primary;
			transition: color 0.2s $easeInOutCubic;			
		}
		
		h2 {
			@extend %title4;
			color: $color-accent-primary;
			transition: color 0.2s $easeInOutCubic;			
		}
		
		p {
			font-size: fs(16px);
			color: $color-text-primary;
			transition: color 0.2s $easeInOutCubic;			
		}

		.picture {
			position: relative;
			z-index: 2;
			display: block;
			width: 100%;
			margin-bottom: 50px;
			transform: translateZ(0);
		}

		.link-overlay {
			position: absolute;
				top: 0;
				left: $grid-gutter-width/2;
			z-index: 5;
			
			width: calc(100% - #{$grid-gutter-width});
			height: 100%;

			&:focus {
				outline: none;
			}

			&:hover {
				~ .content {
					background-color: $color-accent-primary;

					.text {
						[class^=button] {
							@extend %button_white_hover;
						}
	
						h1, h2, p {
							color: $white;
						}
					}
				}
			}
		}

		.content {
			position: relative;
			z-index: 2;
			transform: translateZ(0);
			height: 100%;
			background-color: $color-background-main;
			transition: background-color 0.2s $easeInOutCubic;

			.text {
				position: relative;
				padding: 50px 50px;
				z-index: 3;
				transform: translateZ(0);
			}
		}
	}


	@include at-most($screen-sm-max) {
		.row {
			flex-direction: column;
		}

		.single-cta {
			padding-top: 0px;

			&:nth-child(2) {
				margin-top: 50px;

				.content {
					margin-bottom: 0px;
				}
			}

			.link-overlay {
				&:hover {
					~ .content {
						background-color: transparent;

						.text {
							background-color: $color-accent-primary;
						}
					}
				}
			}

			.content {
				display: flex;
				align-items: flex-start;

				margin-left: 0px;
				margin-bottom: 0px;
				padding-bottom: 0px;
				width: 100%;
				background-color: transparent;

				img {
					width: 40%;
					height: auto;
					flex: 0 0 40%;
					margin-bottom: 0px;
				}

				.text {
					position: relative;
					top: 0px;

					width: 60%;
					flex: 0 0 60%;
					margin-left: 0px;
					padding: 50px;

					background-color: $color-background-main;
					transition: background-color 0.2s $easeInOutCubic;
				}
			}
		}
	}

	@include at-most($screen-xs-max) {
		margin-bottom: 50/320 * 100%;
		
		&:before {
			content: none;
		}
		
		.single-cta {
			.content {
				flex-direction: column;
	
				img {
					width: 100%;
					flex: 0 0 auto;
				}
	
				.text {
					width: 100%;
					
					flex: 0 0 auto;
					padding: 30px;
				}
			}
		}
	}
}