
.bv-cv2-cleanslate .emoji {
	max-width: 1em !important;
}


.product-reviews {
	padding: 50px 0;
	
	@include at-most($screen-xs-max) {
		padding: 40px 0;

		.container {
			padding: 5px;
		}
	}
}