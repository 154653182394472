

.post-header {
	.category {
		text-transform: uppercase;
		letter-spacing: letter-spacing(200);
		font-weight: $font-weight-bold;
		font-size: fs(14px);

		margin-bottom: 35px;
	}
}