

.text-img {
	padding: 130/1920 * 100% 0;
	font-size: fs(14px);
	
	position: relative;
	overflow: hidden;

	&:before {
		content: ' ';
		position: absolute;
			top: 50%;
			left: calc((100% - #{$container-xl}) / 2 - (0.5/12 * #{$container-xl}));

		width: calc(5 / 12 * #{$container-xl});
		height: calc(100% - (180/1920 * 100vw));
		border: 2px solid $color-accent-primary_light;

		transform: translate(0, -50%);

		@include at-most($screen-lg-max){
			left: calc((100% - #{$container-lg}) / 2 - (0.5/12 * #{$container-lg}));
			width: calc(5 / 12 * #{$container-lg});
		}

		@include at-most($screen-md-max){
			left: calc((100% - #{$container-md}) / 2 - (1/12 * #{$container-md}));
			width: calc(6 / 12 * #{$container-md});
			height: calc(100% - (130/1920 * 100vw));			
		}

		@include at-most($screen-sm-max){
			left: calc((100% - #{$container-sm}) / 2 - (0.5/12 * #{$container-sm}));
			width: calc(13 / 12 * #{$container-sm});
			height: calc(100% - (300/1920 * 100vw));

			top: auto;
			bottom: 100/1920 * 100vw;
			transform: none;
		}

		@include at-most($screen-xs-max) {
			display: none;
		}
	}

	.row {
		display: flex;
		align-items: center;

		@include at-most($screen-sm-max) {
			flex-wrap: wrap;
			flex-direction: column-reverse;
			align-items: flex-start;
			
			> div {
				flex: 0 0 100%;

				&:last-child {
					margin-bottom: 30px;
				}
			}
		}
		
	}

	h1 {
		@extend %title2;
		font-size: fs(26px);
	}

	h2 {
		@extend %title3;
		color: $color-accent-primary;
		font-size: fs(20px);
		margin-top: 30px;
	}

	p {
		margin: 5px 0;
	}
	
	a {
		color: $color-text-primary;
		font-weight: $font-weight-bold;

		&:hover {
			color: $color-accent-primary;
			text-decoration: none;
		}
	}

	ul {
		padding-left: 20px;
	}
}