.experience-interactive {
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0,0,0,0.3);
		opacity: 0;
		transition: opacity 0.6s ease;
		z-index: 1;
	}

	&.active:before {
		opacity: 1;
	}

	&.featured {
		&:before {
			background: none;
		}

		.interactive-overlay {
			background: rgba(0,0,0,0.3);
		}
	}
}