.ctn-experience {
	position: relative;
	z-index: 1;
	margin-top: 100px;
	margin-bottom: 100px;
}

%experienceButton {
	border-radius: 0;
	border: none;
	padding: 10px 20px;
	background: transparent;
	font-size: 20px;
	text-transform: uppercase;
	font-family: $font-family-title;
	letter-spacing: 0.08em;
	color: $white;
	cursor: pointer;

	&:after,
	&:before {
		position: relative;
		top: -1px;
		display: inline-block;
		transform: translate(0, 0);
		transition: transform 0.3s ease;
		font-size: 26px;
	}

	&:before {
		content: '{';
		padding-right: 20px;
	}

	&:after {
		content: '}';
		padding-left: 20px;
	}

	&:hover, &:active, &:focus {
		outline: none;
		
		&:before {
			transform: translate(-10px, 0);
		}

		&:after {
			transform: translate(10px, 0);
		}
	}
}

.experience {
	position: relative;
	width: 100%;
	overflow: hidden;
	background: #000;

	&:after {
		content: '';
		display: block;
		position: relative;
		padding-top: 960 / 1920 * 100%;
		z-index: -1;
	}

	/* Preload images */
	&:before {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		opacity: 0;
		background: url(https://s3.amazonaws.com/brim-grange/experience/desktop-home-still-header.jpg), url(
			https://s3.amazonaws.com/brim-grange/experience/pause-overlay.png);
	}

	.splash {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background-image: url(https://s3.amazonaws.com/brim-grange/experience/desktop-home-still-header.jpg);
		background-size: cover;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: radial-gradient(circle at center, rgba(0,0,0,0) 0, rgba(0,0,0,0.6) 80%);
		}

		h1 {
			position: relative;
			color: $white;
			text-align: center;
			z-index: 1;
		}
	}

	.demo-loading {
		@extend %title1;
		font-size: 48 / 1920 * 100vw;
		color: #fff;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
		}

		.loading-text {
			position: relative;

			@include at-most(768px) {
				font-size: 16px;
			}
		}

		.bar {
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 5 / 1920 * 100vw;
			overflow: hidden;
			
			.inner-bar {
				position: relative;
				width: 25%;
				height: 100%;
				background: $color-accent-primary;
				animation: loading-anim 1.2s ease infinite;
			}
		}

		.quote {
			margin-top: 40px;
			text-align: center;
			flex: 0 0 100%;
			padding: 0 20%;
			font-size: 24 / 1920 * 100vw;
			text-transform: none;

			@include at-most(768px) {
				margin-top: 20px;
				font-size: 14px;
			}

			&:before {
				content: '"';
			}

			&:after {
				content: '"';
			}
		}
	}

	@keyframes loading-anim {
		0% {
			transform: translate(-100%, 0);
		}
		100% {
			transform: translate(400%, 0);
		}
	}

	.start-experience {
		z-index: 10;

		@extend %experienceButton;
	}

	.video-skip-button {
		position: absolute;
			top: 50%;
			right: 10px;
		padding: 10px 20px;
		transform: translate(0, -50%);
		color: #fff;
		border: 2px solid #fff;
		z-index: 100;
		transition: background 0.3s ease;
		cursor: pointer;

		&:hover {
			background: rgba(255,255,255,0.5);
		}

		@media screen and (max-width: 568px) {
			padding: 5px 10px;
		}
	}

	@import './_experience-demo-ctn';
	@import './_experience-choices';
	@import './_experience-end-frame';
	@import './_experience-interactive';
	@import './_experience-interactive-overlay';
}

.landscape-only {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #efefef;
	z-index: 1000;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10vw 10vh;

	font-size: fs(18px);
	color: #686868;
	text-align: center;

	img {
		width: 175px;
		margin-bottom: 30px;
	}

	i {
		font-size: 128px;
		transform: rotate(-90deg);
	}

	@media all and (orientation: portrait) {
		display: flex;
	}

	@media (min-width: $screen-sm-max) {
		display: none;
	}
	
}

@import './_experience-nav';