.text-block {
	position: relative;
	margin: 50/1920 * 100% 0;
	text-align: center;

	p {
		font-size: fs(18px);
		&:last-of-type() {
			margin-bottom: 0px;
		}
	}

	@include at-most($screen-xs-max) {
		margin: 30/320 * 100% 0;

		p {
			font-size: fs(16px);
		}
	}
}