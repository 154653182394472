

.header {
	position: relative;
	padding: 200/1920 * 100vw 0;
	margin-bottom: 100px;
	@extend %inverted_colors;

	&:before {
		content: '';
		position: absolute;
			top: -75px;
			left: 50%;
		
		width: calc(50% - 50px);
		height: calc(100% + 125px);
		border: 2px solid $color-accent-primary_light;
		
		@include at-most($screen-md-max) {
			width: calc(60% - 50px);
			left: 40%;
		}
	}

	.container {
		width: 100%;
	}

	[class*="col-"] {
		text-align: center;

		.button-more-big {
			margin-top: 20px;
			
			@include at-most($screen-xs-max) {
				span {
					height: 56px;
					width: 56px;
					line-height: 52px;
					border: 2px solid rgba($color-accent-primary, 1);
			
					i {
						font-size: fs(16px);
					}
				}
			}
		}
	}

	h1 {
		@extend %title1;
		margin-bottom: 0;
		
		@include downsize() {
			@extend %title1;
		}
	}
	
	h2 {
		@extend %title4;
		color: $color-text-secondary;
	}

	p {
		font-size: fs(18px);
	}

	.container {
		position: relative;
		z-index: 2;
	}
	
	.bg {
		position: absolute;
			top: 0;
			left: 0;
		
		width: 100%;
		height: 100%;

		background: center center no-repeat;
		background-size: cover;

		overflow: hidden;

		&:after {
			content: '';
			position: absolute;
				top: 0;
				left: 0;

			width: 100%;
			height: 100%;
			background: rgba($gray21, 0.35);
		}
		
		video {
			display: block;
			min-width: 100%;
			min-height: 100%;

			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&.fixed {
		height: 600/1920 * 100vw;
		padding: 0;
		
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
	
	@include at-most($screen-md-max) {
		&.fixed {
			height: 450/1024 * 100vw;
		}
	}

	@include at-most($screen-sm-max) {
		&.fixed {
			height: 350/768 * 100vw;
		}

		&:before {
			content: none;
		}
	}

	@include at-most($screen-xs-max) {
		margin-bottom: 30/320 * 100vw;

		p {
			font-size: fs(16px);
		}

		&, &.fixed {
			height: auto;
			padding: 60/320 * 100% 0;
		}

		&:before {
			width: 45%;
			height: calc(100% + 110px);
			top: -85px;
		}
	}
}