
.video-overlay {
    position:fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    background: $black;
    transform: translate(0, 110%);
    z-index: 9999;

    transition: transform 0.6s $easeInOutCubic;

    &.opened {
        transform: translate(0, 0);
    }

    .close {
        position: absolute;
            top:20px;
            right:20px;
		
			span {
				position: relative;
				left: 0.5px;
			}
	}

	.share {
		display: none;
		position: absolute;
			top: 80px;
			right: 20px;
		z-index: 2;

		.share-button {
			font-size: 20px;
			
			& + .share-button {
				margin-top: 10px;
			}
		}
	}

	.close, .share-button {
		background: none;
        color: $color-accent-primary;
        font-size: 40px;
        width: 50px;
        height: 49px;
        line-height: 45px;
        text-align: center;
        cursor: pointer;
        z-index: 2;
		border: 2px solid $color-accent-primary_light;

		transition: color 0.3s, background 0.3s;

		&:hover {
			background: $color-accent-primary;
			color: $white;
		}
	}

    .player {
        position: absolute;
            top: 0;
            left: 0;

        width: 100%;
        height: 100%;

        iframe {
            width: 100%;
            height: 100%;
        }

        @include at-most($screen-sm-max) {
            font-family: 'test-player';
            -webkit-overflow-scrolling: touch;
            overflow-y: scroll;
        }
    }
}
