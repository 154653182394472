
.big-cta {
	margin: 50px 0 100px;

	.content-container {
		position: relative;

		&:before {
			content: '';
			position: absolute;
				top: 100px;
				left: -1/8 * 100%;
	
			height: calc(100% - 50px);
			width: 7/8 * 100%;
			border: 2px solid $color-accent-primary_light;
		}
	}

	.content {
		@extend %inverted_colors;
		text-align: center;
		padding: 80px;
		background: center center no-repeat;
		background-size: cover;

		position: relative;
		z-index: 2;

		&:after {
			content: '';
			position: absolute;
				top: 0;
				left: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			display: block;
			background-color: rgba($gray15, 0.65);
		}
	}

	[class^=button] {
		max-width: 300px;
		margin: 40px auto 0;
	}

	@include at-most($screen-xs-max) {
		margin: 50/320 * 100% 0;

		.content-container {
			padding: 0;
			
			&:before {
				top: 35px;
				left: -10px;
				height: calc(100% - 25px);
			}
		}

		.content {
			padding: 30px $grid-gutter-width/2;
		}
	}
}