.interactive-overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display:flex;
	justify-content: center;
	align-content: flex-end;
	flex-direction: column;
	text-align: right;
	// background: url(https://s3.amazonaws.com/brim-grange/experience/pause-overlay.png) no-repeat center center;
	background-size: cover;
	z-index: 100;

	> div {
		width: 40%;
		display: flex;
		justify-content: flex-end;
		overflow: hidden;
		padding-right: 20px;
	}

	.interactive-title {
		@extend %title1;
		padding-top: 0.4em;
		font-size: 60 / 1920 * 100vw;
		color: #fff;
		margin-bottom: 0;
	}

	.interactive-desc {
		font-size: 24 / 1920 * 100vw;
		color: #fff;
		margin-top: 10px;
		line-height: 1.2;
	}

	.interactive-button {
		@extend %experienceButton;
		padding: 0;
		margin-top: 20px;
	}
}