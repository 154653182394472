

input[type=text], 
input[type=email], 
input[type=password] {
	text-transform: uppercase;
	font-family: $font-family-title;
	font-weight: $font-weight-light;
	color: $color-accent-primary;
	border: 1px solid $color-accent-primary;
	padding: 15px 15px;
	font-size: fs(14px);

	display: block;
	width: 100%;
	outline: none;

	@include placeholder($color-accent-primary);
}