.end-frame {
	position: absolute;
		top: 0;
		left: 0;
	width: 100%;
	height: 100%;

	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: url(https://s3.amazonaws.com/brim-grange/experience/end-frame.jpg);
	background-size: cover;

	.title {
		@extend %title;
		font-size: 64 / 1920 * 100vw;

		@include at-most(767px) {
			font-size: 26px;
		}
	}

	.title + div {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}

	a {
		@extend %experienceButton;

		@include at-most(767px) {
			font-size: 16px;
			display: block;
		}

		&:hover {
			text-decoration: none;
		}
	}
}