.post-preview {
	position: relative;
	
	.overlay-link {
		position: absolute;
			top: 0;
			left: 0;
	
		width: 100%;
		height: 100%;
		cursor: pointer;
		z-index: 2;
	}
	
	.post-content {
		padding: 60px 50px;
		background: $gray93;
		color: $color-text-primary;
	
		.categories {
			letter-spacing: letter-spacing(200);
			text-transform: uppercase;
			font-weight: $font-weight-bold;
			font-size: fs(14px);
		}
	
		h1 {
			@extend %title4;
			font-family: $font-family-title;
			font-weight: $font-weight-light;
			color: $color-accent-primary;
			margin-top: 10px;
		}
	
		.btns-row {
			text-align: right;
			margin-top: 30px;
		}
	
		[class^=button] {
			color: $color-accent-primary;
	
			span {
				margin-right: 0;
				margin-left: 10px;
			}
		}
	}

	@include at-most($screen-xs-max) {
		.post-content {
			padding: 30px;
		}
	}
}
