
.single-experience-cta {
	padding: 150/1920 * 100% 0 100/1920 * 100% 0;
	overflow: hidden;

	.container {
		position: relative;

		.col-md-12 {
			&:before {
				content: '';
				height: calc(100% + 100px);
				width: calc(5 / 12 * #{$container-xl});
				border: 2px solid $color-accent-primary_light;
	
				position: absolute;
					right: -0.5 / 12 * 100%;
					top: -50px;
				z-index: 1;
			}
	
			@include at-most($screen-lg-max) {
				&:before {
					width: calc(5 / 12 * #{$container-lg});
				}
			}
	
			@include at-most($screen-md-max) {
				&:before {
					width: calc(5 / 12 * #{$container-md});
					right: -25px;
				}
			}
	
			@include at-most($screen-sm-max) {
				&:before {
					width: calc(5 / 12 * #{$container-sm});
				}
			}

			@include at-most($screen-xs-max) {
				&:before {
					height: calc(100% + 50px);
					top: -25px;
				}
			}

			img {
				position: relative;
				z-index: 2;
				
				@include at-most($screen-sm-max) {
					display: block;
				}
			}
		}
	}

	.content {
		margin-top: -360/565 * 100%;
		// @extend %inverted_colors;
		background: $color-background-main;
		padding: 75/565 * 100%;

		position: relative;
		transform: translateZ(0);
		z-index: 3;

		h1 {
			margin-bottom: 0.1em; 
		}

		h2 {
			@extend %title4;
			color: $color-accent-primary;
		}

		[class^=button] {
			&:hover {
				@extend %button_gold_hover;
			}
		}

		.overlay-link {
			position: absolute;
				top: 0;
				left: 0;

			width: 100%;
			height: 100%;

			&:hover {
				~ [class^=button] {
					@extend %button_gold_hover;
				}
			}
		}
	}

	@include at-most($screen-sm-max) {
		.content {
			margin-top: 0;
			padding: 60/670 * 100%;
		}
	}

	@include at-most($screen-xs-max) {
		
		.button-more {
			margin-top: 20px;
		}

		.container {
			.col-md-12 {
				&:before {
					content: none;
				}
			}
		}
	}
}