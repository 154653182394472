
// Website variables;

$screen-xl: 1640px;
$screen-lg: 1360px;
$screen-md: 1024px;
$container-sm: 700px;
$grid-gutter-width: 30px;

//===================================================================
// Colors
//===================================================================


$black: #111;
$white: #fff;

$brown78: #bfb3ac;
$brown48: #947869;

$gray93: #ebeae6;
$gray75: #b9b9b7;
$gray70: #aaaaaa;
$gray21: #313335;
$gray15: #242526;

$color-text-primary: $gray21;
$color-text-secondary: $gray93;
$color-text-tertiary: $brown48;
$color-text-faded: $gray70;

$color-accent-primary: $brown48;
$color-accent-primary_light: $brown78;
$color-accent-secondary: $white;

$color-background-main: $gray93;
$color-background-light: $white;
$color-background-accent: $color-accent-primary;


//===================================================================
// Fonts
//===================================================================

$font-family-title: "Clarendon LT Std", Helvetica, Arial, sans-serif;
$font-family-text: "Century Gothic", Helvetica, Arial, sans-serif;

$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

$font-size-title1-xl: fs(80px);
$font-size-title1-lg: fs(80px * 0.8);
$font-size-title1-md: fs(80px * 0.6);
$font-size-title1-sm: fs(80px * 0.6);
$font-size-title1-xs: fs(80px * 0.45);

$font-size-title2-xl: fs(60px);
$font-size-title2-lg: fs(60px * 0.75);
$font-size-title2-md: fs(60px * 0.75);
$font-size-title2-sm: fs(60px * 0.75);
$font-size-title2-xs: fs(60px * 0.5);

$font-size-title3-xl: fs(30px);
$font-size-title3-lg: fs(30px);
$font-size-title3-md: fs(30px * 0.75);
$font-size-title3-sm: fs(30px * 0.75);
$font-size-title3-xs: fs(30px * 0.75);

$font-size-title4-xl: fs(20px);
$font-size-title4-lg: fs(20px);
$font-size-title4-md: fs(20px * 0.75);
$font-size-title4-sm: fs(20px * 0.75);
$font-size-title4-xs: fs(20px * 0.6);

$font-size-text-xl: fs(16px);
$font-size-text-lg: fs(16px);
$font-size-text-md: fs(16px);
$font-size-text-sm: fs(16px);
$font-size-text-xs: fs(16px);

$font-size-button-xl: fs(14px);
$font-size-button-lg: fs(14px);
$font-size-button-md: fs(14px);
$font-size-button-sm: fs(14px);
$font-size-button-xs: fs(14px);