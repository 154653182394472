

.single-post {

	.share {
		position: relative;
		width: 180px;
		transform-origin: 50% 200%;
		transform: rotate(-90deg);

		span {
			display: inline-block;
			margin-left: 20px;
			text-transform: uppercase;
			font-family: $font-family-title;
			font-weight: $font-weight-light;
			color: $color-accent-primary;
		}
		
		i {
			position: relative;
			display: inline-block;
			transform: rotate(90deg);
			color: $color-accent-primary;

			& + i {
				margin-left: 10px;
			}

			&:hover {
				text-decoration: none;
				color: darken($color-accent-primary, 10%);
			}
			
			&:focus {
				text-decoration: none;
			}
		}
	}

	.author {
		display: flex;
		padding-bottom: 40px;
		margin-bottom: 80px;
		border-bottom: 2px solid $color-accent-primary;
		
		.content {
			margin-left: $grid-gutter-width;
			font-size: fs(14px);

			.social {
				font-size: fs(18px);
				margin-top: 10px;

				a {
					color: $color-accent-primary;
				}
			}
		}
	}
	
	.single-post-content {
		padding: 50px 0;
		background: $color-background-light;

		.back-link {
			text-transform: uppercase;
			letter-spacing: letter-spacing(200);
			color: $color-accent-primary;
			font-weight: $font-weight-bold;

			font-size: fs(14px);

			display: inline-block;
			margin-bottom: 80px;

			transition: color 0.3s ease;

			&:hover {
				text-decoration: none;
				color: darken($color-accent-primary, 10%);
			}
			
			&:focus {
				text-decoration: none;
			}
		}

		h1, h2, h3 {
			margin-top: 1.5em;
			font-family: $font-family-title;
			font-weight: $font-weight-light;
		}

		blockquote {
			color: $color-text-faded;
			font-style: italic;
			margin: 2em 0;

			> p {
				&:before {
					content: '" ';
				}
	
				&:after {
					content: ' "';
				}
			}
		}

		p > img {
			display: flex;
			margin: 3em auto;
		}
	}

	.related-products {
		margin-top: 60px;
		padding-top: 60px;
		border-top: 2px solid $color-accent-primary;

		h2 {
			font-size: fs(20px);
			margin-bottom: 1em;
			margin-top: 0;
		}

		p {
			margin: 0.25em 0;
		}

		a {
			font-weight: $font-weight-bold;
			color: $color-accent-primary;
			text-transform: uppercase;

			&:hover {
				color: $color-text-primary;
				text-decoration: none;
			}
		}
	}

	@include at-most($screen-xs-max) {
		.single-post-content {
			padding: 50/320 * 100% 0;

			.back-link {
				margin-bottom: 30px;
			}
		}

		.author {
			display: block;
			padding-bottom: 15px;
			margin-bottom: 20/320 * 100%;

			.content {
				margin-left: 0;
				margin-top: 15px;
			}
		}

		.share {
			transform: none;
			display: flex;
			flex-direction: row-reverse;
			width: auto;
			justify-content: flex-end;
			margin-bottom: 30px;

			span {
				margin-left: 0;
			}
	
			a {
				transform: none;
			}
		}

		.related-products {
			margin-top: 30/320 * 100%;
			padding-top: 50/320 * 100%;
		}
	}
}