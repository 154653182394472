.demo-ctn {
	position: absolute;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	.experience-demo {
		width: 100%;
		height: 100%;

		display: flex;
		align-items: center;
		justify-content: center;
	}

	.continue-button {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
		
		@extend %title1;
		color: $white;
		background: rgba(0,0,0,0.2);
	}

	.experience-interactive,
	.experience-video {
		position: relative;
		width: 100%;
		height: auto;
		overflow: hidden;

		&:after {
			content:'';
			display: block;
			padding-top: 9 / 16 * 100%;
			width: 100%;
		}

		canvas, video {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
		}

		svg {
			position: absolute;
				top: 0;
				left: 0;
			display: block;
			width: 100%;
			height: 100%;
			z-index: 50;

			#path, #path_anim {
				pointer-events: none;
			}

			#cursor {
				cursor: grab;
			}

			#check, #label {
				user-select: none;
			}

			#cursor #arrow {
				opacity: 1;
				transform: opacity 0.3s ease;
			}

			&.dragging {
				cursor: grabbing;

				#cursor {
					cursor: grabbing;

					> * {
						pointer-events: none;
					}

					#arrow {
						opacity: 0;
					}
				}
			}

			&.complete {
				#cursor #arrow {
					opacity: 0;
				}
			}
		}
	}
}