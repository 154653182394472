

.bv-cv2-cleanslate .bv-core-container-33 {
	.bv-popup-histogram {
		padding-bottom: 15px !important;
		
		.bv-action-bar {
			display: none !important;
		}
	}

	.bv-primarySummary-rating-container .bv-rating-stars-container .bv-rating-stars.bv-rating-stars {
		font-size: 30px !important;
	}
}


.product-infos {
	padding: 80/1920 * 100% 0;
	background-color: $color-background-light;

	.bv-details-bar,
	.bv-action-bar {
		display: none !important;
	}

	.bv-rating-label {
		font-size: 0 !important;

	}

	.big-slider {
		margin-bottom: 50px;

		.slick-track {
			display: flex;
		}
		
		.slide {
			display: flex;
			align-items: center;
			justify-content: center;
			height: auto;
	
			img {
				display: inline;
				flex: 0 0 auto;
			}

			&.video-big {
				cursor: pointer;
			}
		}
	}

	.thumb-slider {
		.slick-arrow:before {
			font-family: $font-family-text;
			color: $color-accent-primary;
		}

		.slick-prev {
			left: -40px;
		}

		.slick-next {
			right: -40px;
		}

		/* the slides */
		.slick-slide {
			margin: 0 20px;
		}
		/* the parent */
		.slick-list {
			margin: 0 -20px;
		}

		.slide {
			outline: none;
			cursor: pointer;
			position: relative;

			&:after {
				content: '';
				transition: transform 0.3s;
				transform: scale(0, 1);
				position: absolute;
					bottom: 0;
					left: 0;
				
				height: 4px;
				width: 100%;
				background: $color-accent-primary;
			}

			&.video-thumb {
				position: relative;

				// img {
				// 	max-width: none;
				// 	max-height: 100%;
				// }

				[class^=button] {
					position: absolute;
						top: 50%;
						left: 50%;
					
					transform: translate(-50%, -50%);
					@extend %button_inverted;

					span {
						margin-right: 0;
					}
				}
			}

			& + .slide {
				// margin-left: 40px;
			}
		}

		.slick-current {
			&:after {
				transform: scale(1, 1);
			}
		}
	}

	h1 {
		@extend %title3;
		font-family: $font-family-title;
		font-weight: $font-weight-light;
		margin-bottom: 0px;
	}

	p.small {
		font-size: fs(14px);
	}

	.reviews {
		display: flex;
		align-items: center;
		margin: 40px 0;

		.association {
			padding-right: 20px;
			margin-right: 20px;
			border-right: 1px solid $gray75;
		}
	}

	@include at-most($screen-xs-max) {
		.big-slider {
			margin-bottom: 0;
		}

		.thumb-slider {
			margin: 0 15px 30px 15px;

			.slick-prev {
				left: -25px;
			}
	
			.slick-next {
				right: -25px;
			}

			/* the slides */
			.slick-slide {
				margin: 0 10px;
			}
			/* the parent */
			.slick-list {
				margin: 0 -10px;
			}
		}
	}
}