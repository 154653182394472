html {
	font-size: 100%;
}

body {
	// background: url('../img/brim-pattern.jpg') repeat $color-background-main;
	background: $white;

	&.opened {
		overflow: hidden;
	}
}

.page-wrap {
	position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
	
	//important sinon le inpage nav passe pardessus
	&.opened {
		z-index: 2; 
	}

	&:not([data-has-scrollbar]) {
		position: relative;
		top: auto;
		right: auto;
		left: auto;
		bottom: auto;

		overflow: auto;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
		padding: 100px 60px 0;
		background: $white;
		
		@include at-most($screen-md-max) {
			padding: 100px 25px 0;
		}
		
		@include at-most($screen-sm-max) {
			padding: 55px 25px 0;		
		}
	
		@include at-most($screen-xs-max) {
			padding: 85px $grid-gutter-width/2 0;
			overflow-x: hidden;
		}
	}

	&.experience-wrapper {
		padding: 0 !important;
		min-height: 100vh !important;
	}
}

.page-id-362, .page-id-376 {
	.text-block {
		margin-top: 100px;

		@include at-most($screen-xs-max) {
			margin-top: 50px;
		}
	}
}

.home {
	.header {
		padding: 0;
		height: calc(100vh - 200px);
		min-height: 675px;
		display: flex;
		align-items: center;

		[class*="col-"] {
			text-align: left;
		}

		// .bg {
		// 	&:after {
		// 		height: calc(100vh - 200px);
		// 	}
		// }

		@include at-most($screen-sm-max) {
			min-height: 500px;
		}

		@include at-most($screen-sm-max) {
			padding: 200/1920 * 100vw 0;
			height: 100%;
			min-height: 350px;
		}
	}

	.full-tout {
		position: relative;
		padding-bottom: 270px;
		// padding-bottom: 70px;
		margin: 100px 0 0 0;
		overflow: hidden;
		background: url('../img/home-bg.png') left bottom no-repeat;
		background-size: contain;

		@include at-most($screen-sm-max) {
			padding-bottom: 50px;
			background: none;
		}
	}

	// .experience-ctas {
	// 	&:before, &:after {
	// 		content: '';
	// 		position: absolute;
	// 			top: 0;
	// 			right: 0;
	// 		width: 485px;
	// 		height: 804px;
	// 		margin-right: -60px;
	// 		background-image: url(../img/bg-1.png);
	// 		background-repeat: no-repeat;
	// 	}

	// 	&:after {
	// 		bottom: 0;
	// 		left: 0;
	// 		background-image: url(../img/bg-2.png);
	// 	}
	// }
}

.scroll-content {
	position: relative;
	padding: 100px 60px 0;
	// background: url('../img/brim-pattern.jpg') repeat $color-background-main;
	background: $white;
	
	@include at-most($screen-md-max) {
		padding: 100px 25px 0;
	}
	
	@include at-most($screen-sm-max) {
		padding: 55px 25px 0;		
	}

	@include at-most($screen-xs-max) {
		padding: 85px $grid-gutter-width/2 0;
		overflow-x: hidden;
	}
}

@include at-most($screen-xs-max) {
	.container {
		width: 100%;
	}
}

img {
	max-width: 100%;
}
