body {
	> .sticky-nav {
		// display: none;
		width: calc(100% - 120px);
		position: fixed;
			top: 0;
			left: 60px;
		z-index: 10;
		margin-top: 0;

		.inpage-nav-wrapper {
			padding-bottom: 0px;
		}
		
		&:after {
			content: "";
			position: absolute;
				bottom: 0px;
				left: 15%;
				right: 15%;
			z-index: -1;

			width: 70%;
			height: 20px;
			border-radius: 35%;

			box-shadow: 0 0 20px rgba(0,0,0,0.2);
		}

		@include at-most($screen-md-max) {
			width: calc(100% - 50px);
			left: 25px;			
		}
		@include at-most($screen-sm-max) {
			width: calc(100% - (#{$grid-gutter-width}));
			left: $grid-gutter-width/2;			
		}
	}
}

.inpage-nav-wrapper {
	background-color: $color-background-light;
	padding-bottom: 10px;

	.scroll-content {
		background: $white;
		padding: 0px;
		overflow: visible;
	}
	
	.inpage-nav {
		overflow-x: auto;
		overflow-y: hidden;
	}

	[data-scrollbar] &{
		.inpage-nav {
			overflow-x: inherit;
			overflow-y: inherit;
		}
	}

	@include at-most($screen-xs-max) {
		&:after {
			content: '';
			position: absolute;
				top: 0;
				right: 0;
			width: 30%;
			height: 100%;
			pointer-events: none;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#7db9e8+1,242526+100&0+0,0.2+100 */
			background: -moz-linear-gradient(left, rgba(125,185,232,0) 0%, rgba(125,185,232,0) 1%, rgba(36,37,38,0.2) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, rgba(125,185,232,0) 0%,rgba(125,185,232,0) 1%,rgba(36,37,38,0.2) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right, rgba(125,185,232,0) 0%,rgba(125,185,232,0) 1%,rgba(36,37,38,0.2) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#33242526',GradientType=1 ); /* IE6-9 */
		}
	}
}

.sticky-nav {
	position: relative;
	z-index: 1;
	background-color: $color-background-light;
	margin-top: -100px;

	@include at-most($screen-xs-max) {
		margin-top: -30/320 * 100vw;
	}
}

.inpage-nav-container {
	position: relative;
	background: $color-background-light;

	@include at-most($screen-sm-max) {
		padding: 0px;
	}
}

.inpage-nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	margin: 0 auto;

	&:after {
		content: "";
		position: absolute;
			bottom: 0px;
			left: 15%;
			right: 15%;
		z-index: -1;

		width: 70%;
		height: 50%;
		border-radius: 35%;

		box-shadow: 0 0 20px rgba(0,0,0,0.2);
	}
}

.nav-item {
	flex: 1 1 auto;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	color: $color-text-primary;
	font-weight: $font-weight-bold;
	font-size: fs(16px);
	background-color: $white;

	transition: color 0.3s, background 0.3s;

	&:focus  {
		color: $color-text-primary;
		text-decoration: none;
	}

	&:hover, &.current {
		color: $color-text-secondary;
		background: $color-accent-primary;
		text-decoration: none;
	}
}

@include at-most($screen-sm-max) {
	.inpage-nav {
		height: 60px;
	}

	.nav-item {
		font-size: fs(14px);
	}
}

@include at-most($screen-xs-max) {
	.inpage-nav {
		.nav-item {
			min-width: 250px;
		}
	}

	.nav-item {
		width: 120px;
		flex: 0 0 auto;
		text-align: center;
	}

	.inpage-nav-wrapper {
		padding: 0;
	}
}