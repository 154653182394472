.product-header {
	.shop-links {
		margin-top: 40px;

		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		&[class^=button] {
			width: calc(25% - #{$grid-gutter-width / 2});
			margin: 50px auto 0 auto;
			cursor: pointer;
		}
	}

	@include at-most($screen-md-max) {
		.shop-links {
			margin-top: 20px;

			&[class^=button] {
				width: calc(50% - #{$grid-gutter-width / 2});
				padding: 20px 0;
			}
		}
	}

	@include at-most($screen-xs-max) {
		.shop-links {
			display: block;
		}
	}
}

.product-infos {
	.shop-links {
		margin-top: 40px;

		// display: flex;
		// justify-content: space-between;
		// flex-wrap: wrap;

		&[class^=button] {
			width: calc(50% - #{$grid-gutter-width / 2});
			margin-bottom: $grid-gutter-width;

			@include at-most($screen-md-max) {
				width: calc(70% - #{$grid-gutter-width / 2});
			}
		}
	}

	@include at-most($screen-xs-max) {
		.shop-links {
			margin-top: 30px;
			
			&[class^=button] {
				height: 60px;
				width: 100%;
				margin-bottom: 15px;
			}
		}
	}
}

.shop-btns {
	[data-shop-content] {
		display: none;
	}
}

.shop-overlay {
	visibility: hidden;
	opacity: 0;
	position: fixed;
		top: 0;
		left: 0;
	z-index: 10;
	width: 100%;
	height: 100vh;
	background-color: rgba($color-background-main, 0.7);

	transition: visibility 0s linear 0.5s, opacity 0.2s $easeInOutCubic 0.3s;

	.shop-links {
		opacity: 0;
		visibility: hidden;

		position: absolute;
			top: 50%;
			left: 50%;
		transform: translate(-50%, -50%);
		width: calc(10 / 12 * #{$container-xl});
		min-height: 50vh;

		background-color: $color-background-light;

		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 130px;
		
		transform-origin: center center;
		transition: visibility 0s linear 0.3s, opacity 0.3s $easeInOutCubic;

		.close {
			position: absolute;	
				top: 30px;
				right: 30px;
			font-size: fs(40px);
			line-height: 30px;

			cursor: pointer;
		}

		.btns {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			width: 100%;

			.button-shop {
				width: calc((100% - #{$grid-gutter-width}) / 3);
				background-color: $color-background-light;
				margin: 0 5px;

				// &:first-of-type {
				// 	margin-left: 0px;
				// }

				&:nth-child(n+4) {
					margin-top: 10px;
				}

				svg {
					max-width: 100%;
				}
	
				#svg1936 {
					max-height: 35px;
					width: auto;
				}
			}
		}

		.availability {
			@extend %title3;
			font-weight: $font-weight-light;
			font-family: $font-family-title;
			text-align: center;
			margin: 0 5px 50px 5px;
		}
		
		@include at-most($screen-lg-max) {
			width: calc(10 / 12 * #{$container-lg});
		}

		@include at-most($screen-md-max) {
			width: calc(10 / 12 * #{$container-md});
			padding: 100px;
		}

		@include at-most($screen-sm-max) {
			width: calc(10 / 12 * #{$container-sm});
			padding: 50px;
		}

		@include at-most($screen-xs-max) {
			width: calc(100% - #{$grid-gutter-width});
			padding: 50px;

			.btns {
				flex-wrap: wrap;

				.button-shop {
					width: 100%;
					margin: 5px 0;
				}
			}

			.availability {
				margin-bottom: 30px;
			}
		}
	}

	&.opened {
		opacity: 1;
		visibility: visible;
		transition: visibility 0s linear, opacity 0.2s $easeInOutCubic;

		/* el problème c'Est qui est pas vraiment dans le div encore */
		.shop-links {
			transition: visibility 0s linear, opacity 0.3s $easeInOutCubic 0.2s;
			opacity: 1;
			visibility: visible;
		}
	}
}