

.img-three-column {
	padding: 100px 0 0 0;
	font-size: fs(14px);
	
	position: relative;
	overflow: hidden;

	&:before {
		content: ' ';
		position: absolute;
			top: 50px;
			right: calc((100% - #{$container-xl}) / 2 - (-1/12 * #{$container-xl}));

		width: calc(5 / 12 * #{$container-xl});
		height: calc(100% - (520/1920 * 100vw));
		border: 2px solid $color-accent-primary_light;

		@include at-most($screen-lg-max){
			right: calc((100% - #{$container-lg}) / 2 - (-1/12 * #{$container-lg}));
			width: calc(5 / 12 * #{$container-lg});
		}

		@include at-most($screen-md-max){
			right: calc((100% - #{$container-md}) / 2 - (-1/12 * #{$container-md}));
			width: calc(5 / 12 * #{$container-md});
			height: calc(100% - (520/1920 * 100vw));		
		}

		@include at-most($screen-sm-max){
			right: calc((100% - #{$container-sm}) / 2 - (-1/12 * #{$container-sm}));
			width: calc(5 / 12 * #{$container-sm});
			height: calc(100% - (800/1920 * 100vw));	

			top: 20px;
		}

		@include at-most($screen-xs-max) {
			display: none;
		}
	}

	.text-container {
		display: flex;
		justify-content: space-around;
		padding: 100px $grid-gutter-width/2 50px $grid-gutter-width/2;


		h1 {
			@extend %title3;
			font-weight: $font-weight-light;
			font-family: $font-family-title;
			margin-bottom: 0px;
		}
	
		h2 {
			@extend %title3;
			color: $color-accent-primary;
			font-size: fs(20px);
		}
	
		p {
			margin: 5px 0;
		}
		
		a {
			color: $color-text-primary;
			font-weight: $font-weight-bold;
	
			&:hover {
				color: $color-accent-primary;
				text-decoration: none;
			}
		}
	
		ul {
			padding-left: 20px;
		}

		.text-column {
			padding: 0 $grid-gutter-width/2;
		}

		@include at-most($screen-sm-max) {
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 50px $grid-gutter-width/2 0 $grid-gutter-width/2;
			
			.text-column:first-of-type() {
				margin-bottom: 20px;
				width: 100%;
				flex: 0 0 100%;
			}
		}

		@include at-most($screen-xs-max) {
			padding: 30px $grid-gutter-width/2 0 $grid-gutter-width/2;

			.text-column {
				margin-bottom: 20px;

				&:last-of-type() {
					margin-bottom: 0px;
				}
			}
		}
	}

	@include at-most($screen-sm-max) {
		padding: 50px 0;
	}
	@include at-most($screen-sm-max) {
		padding: 30px 0;
	}
}