.choices {
	position: absolute;
	top: 0;
	left:0;
	width: 100%;
	height: 100%;
	z-index: 1;

	display: flex;
	align-items: stretch;
	
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0,0,0,0.3);
		opacity: 0;
		transition: opacity 0.6s ease;
		z-index: 1;
	}

	&.active:before {
		opacity: 1;
	}

	.choice-ctn {
		position: relative;
		flex: 0 0 50%;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		z-index: 2;
	}

	.choice-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 200%;
		height: 100%;
		pointer-events: none;
		background-size: cover;

		opacity: 0;

		transition: opacity 0.3s ease;
	}

	.choice-ctn:nth-child(2) {
		.choice-overlay {
			right: 0;
			left: auto;
		}
	}

	.choice {
		position: relative;
		flex: 1 1 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: center;
		cursor: pointer;
		color: $white;
		padding-top: 10%;

		&:after {
			content: '';
			position: relative;
			left: 0px;
			width: 2px;
			height: calc(80 / 1920 * 100vw);
			margin-top: 20px;
			background: $white;
			transform: scale(1, 0);
			transform-origin: bottom center;
			transition: transform 0.3s ease;
		}
		
		.title {
			@extend %title1;
			font-size: calc(80 / 1920 * 100vw);
			color: $white;
			padding-top: 0.1em;
			margin-bottom: 0;
			transform: translate(0, 100%);
			transition: transform 0.3s $easeOutExpo;
		}

		.choice-try-ctn {
			position: relative;

			&:after {
				position: absolute;
				top: 50%;
				right: -20px;
				margin-left: 10px;
				content: '}';
				transform: translate(0, -50%);
				transition: transform 0.3s ease, opacity 0.3s ease;
				opacity: 0;
			}

			&:before {
				position: absolute;
				// top: 0;
				// right: 100%;
				top: 50%;
				left: -20px;
				margin-right: 10px;
				content: '{';
				transform: translate(0, -50%);
				transition: transform 0.3s ease, opacity 0.3s ease;
				opacity: 0;
			}
			
			.choice-mask {
				transform: scale(0, 1);
				transition: transform 0.3s ease;

				.choice-try {
					transform: scale(2, 1);
					transition: transform 0.3s ease;
					text-transform: uppercase;
					text-decoration: underline;
					font-family: $font-family-title;
					padding-top: 4px;
				}
			}
		}

		@mixin hover-styles {
			&:after {
				transform: scale(1, 1);
				transition: transform 0.3s ease, opacity 0.3s ease;
			}
			
			.title {
				transform: translate(0, 0);
				transition: transform 0.3s ease, opacity 0.3s ease;
			}

			.choice-try-ctn {
				&:after, &:before {
					transform: translate(0, -50%);
					opacity: 1;
					transition: transform 0.3s ease, opacity 0.3s ease;
				}

				.choice-mask {
					transform: scale(1, 1);
					transition: transform 0.3s ease, opacity 0.3s ease;

					.choice-try {
						transform: scale(1, 1);
						transition: transform 0.3s ease, opacity 0.3s ease;
					}
				}
			}

			& + .choice-overlay {
				opacity: 0.6;
			}
		}

		&:hover {
			@include hover-styles();
		}

		@media (max-width: 1024px) {
			@include hover-styles();
		}
	}

	.choice-button {
		width: 26px;
		height: 26px;
		border-radius: 100%;
		border: 8px solid rgba(255,255,255,0.2);
		position: absolute;
			top:100%;
			left: 50%;
		transform: translate(-50%, -50%);

		&:before {
			position: absolute;
				top: 50%;
				left: 50%;
			content: '';
			width: 28px;
			height: 28px;
			border-radius: 100%;
			border: 2px solid $white;
			transform: translate(-50%, -50%);
		}
	}

	.choice-mask {
		overflow: hidden;
	}
}