

%title {
	margin: 0 0 0.5em;
	line-height: 1;
	font-weight: $font-weight-light;
	font-family: $font-family-title;
	text-transform: uppercase;
}

%title1 {
	@extend %title;
	font-size: $font-size-title1-xl;
	line-height: calc(14/16);
	@include responsive-fontsizes((
		$screen-lg-max: $font-size-title1-lg,
		$screen-md-max: $font-size-title1-md,
		$screen-sm-max: $font-size-title1-sm,
		$screen-xs-max: $font-size-title1-xs
	));
}

%title2 {
	@extend %title;
	font-size: $font-size-title2-xl;

	@include responsive-fontsizes((
		$screen-lg-max: $font-size-title2-lg,
		$screen-md-max: $font-size-title2-md,
		$screen-sm-max: $font-size-title2-sm,
		$screen-xs-max: $font-size-title2-xs
	));
}

%title3 {
	@extend %title;
	// text-transform: none;
	font-family: $font-family-text;
	font-weight: $font-weight-bold;
	font-size: $font-size-title3-xl;
	@include responsive-fontsizes((
		$screen-lg-max: $font-size-title3-lg,
		$screen-md-max: $font-size-title3-md,
		$screen-sm-max: $font-size-title3-sm,
		$screen-xs-max: $font-size-title3-xs
	));
}

%title4 {
	@extend %title;
	line-height: calc(25/18);
	letter-spacing: letter-spacing(100);
	font-family: $font-family-text;
	font-weight: $font-weight-bold;
	font-size: $font-size-title4-xl;

	@include responsive-fontsizes((
		$screen-lg-max: $font-size-title4-lg,
		$screen-md-max: $font-size-title4-md,
		$screen-sm-max: $font-size-title4-sm,
		$screen-xs-max: $font-size-title4-xs
	));
}

%text {
	line-height: calc(30/18);
	letter-spacing: letter-spacing(100);
	font-family: $font-family-text;
	font-size: $font-size-text-xl;
	@include responsive-fontsizes((
		$screen-lg-max: $font-size-text-lg,
		$screen-md-max: $font-size-text-md,
		$screen-sm-max: $font-size-text-sm,
		$screen-xs-max: $font-size-text-xs
	));
}

%inverted_colors {
	color: $color-text-secondary;
}

* {
	-webkit-font-smoothing: antialiased;
}

@mixin downsize() {
	section &,
	article & {
		@content;
	}
}

h1 {
	@extend %title1;

	@include downsize() {
		@extend %title2;

		@include downsize() {
			@extend %title3;
		}
	}
}

h2 {
	@extend %title2;
	
	@include downsize() {
		@extend %title3;
	}
}

h3 {
	@extend %title3;
}

body {
	@extend %text;
	color: $color-text-primary;
}

p {
	margin: 0 0 1em 0;

	> a:not([class]) {
		color: $color-accent-primary;
		font-weight: $font-weight-bold;	
	}
}