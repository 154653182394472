

.video-cta {
	padding: 50px 0;

	.overlay-link {
		position: absolute;
			top: 0;
			left: 0;

		width: 100%;
		height: 100%;
		z-index: 2;
	}

	.picture {
		position: relative;
		transform: translateZ(0);
		z-index: 3;

		img {
			opacity: 0;
			transform: translate(0, 30px);
	
			transition: opacity 0.3s $easeInOutCubic, transform 0.5s $easeInOutCubic;
	
			&.active {
				opacity: 1;
				transform: translate(0, 0px);
			}
		}

		&:before {
			content: '';
			position: absolute;
				top: 45px;
				right: -30px;
			
			width: calc(100% - #{$grid-gutter-width});
			height: 100%;
			border: 2px solid $color-accent-primary_light;

			@include at-most($screen-md-max) {
				top: 24px;
				right: -10px;
			}

			@include at-most($screen-xs-max) {
				content: none;
			}
		}

		&:hover {
			+ .cta-content .content {
				[class^=button] {
					@extend %button_gold_hover;
				}
			}
		}
	}

	.cta-content {
		position: relative;

		margin-left: calc(-3/12 * #{$container-xl});

		@include at-most($screen-lg-max) {
			margin-left: calc(-3/12 * #{$container-lg});
		}
		
		@include at-most($screen-md-max) {
			margin-left: calc(-3/12 * #{$container-md});
		}
		
		@include at-most($screen-sm-max) {
			margin-left: 0;
			margin-top: 0;
		}
	}

	.content {
		padding-left: (4/10 * 100%);
		width: 100%;
		position: relative;
		z-index: 2;

		h1 {
			@extend %title3;
			font-weight: $font-weight-light;
			font-family: $font-family-title;
		}

		[class^=button] {
			color: $color-text-primary;

			span {
				color: $color-accent-primary;
			}

			&:hover {
				@extend %button_gold_hover;
			}
		}

		.steps {
			@extend %title4;
			color: $color-accent-primary;
			margin: 20px 0;
		}

		@include at-most($screen-sm-max) {
			padding: (100/1200 * 100%) (1/10 * 100%);
		}
	}

	&.inverted-layout {
		.cta-content {
			margin-left: 0;
		}

		.content {
			padding-left: 1/10 * 100%;
			padding-right: 4/10 * 100%;

			@include at-most($screen-sm-max) {
				padding-right: 1/10 * 100%;
			}
		}

		.picture {
			margin-left: calc(-3/12 * #{$container-xl});

			&:before {
				right: auto;
				left: -30px;
	
				@include at-most($screen-md-max) {
					left: -10px;
				}
			}

			@include at-most($screen-lg-max) {
				margin-left: calc(-3/12 * #{$container-lg});
			}
			
			@include at-most($screen-md-max) {
				margin-left: calc(-3/12 * #{$container-md});
			}
			
			@include at-most($screen-sm-max) {
				margin-left: 0;
			}
		}
	}

	@include at-most($screen-sm-max) {
		.picture {
			img {
				width: 100%;
			}
		}
	}

	@include at-most($screen-xs-max) {
		padding: 30px 0px;

		.cta-content,
		.picture {
			padding: 0;
		}

		.cta-content {
			.content {
				padding: (100/1200 * 100%) 15px 0 15px;
			}

			&:before {
				content: none;
			}
		}
	}
}