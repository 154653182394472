

%base_button {
	@extend %title;
	margin-bottom: 0;
	display: inline-block;
	vertical-align: middle;
	font-family: $font-family-text;
	font-weight: $font-weight-bold;
	font-size: $font-size-button-xl;
	text-transform: uppercase;

	@include responsive-fontsizes((
		$screen-lg-max: $font-size-button-lg,
		$screen-md-max: $font-size-button-md,
		$screen-sm-max: $font-size-button-sm,
		$screen-xs-max: $font-size-button-xs
	));
}

%button_more {
	display: flex;
	align-items: center;
	color: $color-accent-primary;

	line-height: (20/16);
	letter-spacing: letter-spacing(200);

	
	transition: color 0.3s $easeInOutCubic;
	
	p {
		margin-bottom: 0px;
	}

	span {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		height: 56px;
		width: 56px;
		line-height: 52px;
		text-align: center;
		margin-right: 10px;
		border: 2px solid rgba($color-accent-primary, 0.25);
		border-radius: 100%;

		flex: 0 0 auto;
		transition: border-color 0.3s $easeInOutCubic;

		i {
			position: absolute;
				top: 50%;
				left: 50%;
			transform: translate(-50%, -50%);
			z-index: 3;
			font-size: fs(16px);
			color: $color-accent-primary;

			&:before {
				position: relative;
				left: 1.3px;
				width: auto;
				margin: 0;
				display: block;
			}
		}

		&:after {
			content: '';
			position: absolute;
				top: 50%;
				left: 50%;

			transform: translate(-50%, -50%);

			width: 100%;
			height: 100%;
			background-color: transparent;
			border: 8px solid rgba($color-accent-primary, 0.1);
			border-radius: 100%;
			transition: border 0.3s $easeInOutCubic, background-color 0.3s $easeInOutCubic;
		}
	}

	&:hover {
		text-decoration: none;
	}
}

%button_simple {
	text-align: center;
	display: block;
	width: 100%;
	border: 2px solid $color-text-secondary;
	padding: 25px 0;
	color: $color-text-secondary;
	letter-spacing: letter-spacing(100);

	transition: color 0.3s, background 0.3s;

	&:hover {
		color: $color-text-primary;
		background: $color-text-secondary;
		text-decoration: none;
	}
}

%button_shop {
	border: 2px solid $color-accent-primary;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 30px;
	cursor: pointer;
	color: $color-accent-primary;
	
	transition: color 0.3s, background 0.3s;
	
	&:hover {
		color: $color-text-secondary;
		background-color: $color-accent-primary;
	}
}

%button_inverted {
	span {
		color: $color-accent-primary;

		&:before {
			border-color: $brown78;
		}

		&:after {
			background-color: $gray93;
		}
	}
}

%button_play {
	@extend %button_more;
	@extend %button_inverted;

	span {
		border-color: rgba($color-accent-primary, 1);

		i {
			margin-left: 0.7px;
		}

		&:after {
			border: 4px solid rgba($color-accent-primary, 0.35);
			background-color: $gray93;
		}
	}
}

%button_big {
	@extend %button_inverted;
	color: $color-accent-secondary;
	
	span {
		height: 88px;
		width: 88px;
		line-height: 84px;
		border: 4px solid rgba($color-accent-primary, 1);

		i {
			font-size: fs(20px);
		}
		
		&:after {
			width: 100%;
			height: 100%;
			
			border: 10px solid rgba($color-accent-primary, 0.35);
			transition: border 0.2s $easeInOutCubic;			
		}
	}

	&:hover {
		span:after {			
			border: 6px solid rgba($color-accent-primary, 0.35);
		}
	}
}

%button_white {
	color: $color-text-secondary;
	
	span {
		border-color: rgba($white, 0.25);

		i {
			color: $white;			
		}

		&:after {
			border-color: rgba($white, 0.1);
		}
	}
}

%button_white_hover {
	color: $color-text-secondary;
	transition: color 0.2s $easeInOutCubic;
	
	span {
		border-color: rgba($white, 0.25);
		transition: border-color 0.2s $easeInOutCubic;
		
		i {
			color: $white;
			transition: color 0.2s $easeInOutCubic;
		}
		
		&:after {
			border: 4px solid rgba($white, 0.1);
			background-color: rgba($white, 0.2);
			transition: border 0.2s $easeInOutCubic, background-color 0.2s $easeInOutCubic;
		}
	}
}

%button_gold_hover {
	color: darken($color-accent-primary, 10%);
	transition: color 0.3s $easeInOutCubic;

	span {
		border: 2px solid rgba($color-accent-primary, 0.8);
		transition: border-color 0.3s $easeInOutCubic;
		
		&:after {
			border: 4px solid rgba($color-accent-primary, 0.2);
			background-color: rgba($color-accent-primary, 0.1);
			transition: border 0.3s $easeInOutCubic, background-color 0.3s $easeInOutCubic;
		}
	}
}

[class^=button] {
	@extend %base_button;
	
	&[class*=-more] {
		@extend %button_more;
	}

	&[class*=-white] {
		@extend %button_white;
	}

	&[class*=-simple] {
		@extend %button_simple;
	}

	&[class*=-shop] {
		@extend %button_shop;
	}

	&[class*=-big] {
		@extend %button_big;

		i {
			font-size: fs(22px);
		}
	}
}