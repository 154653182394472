

.related-posts {
	padding: 50/1920 * 100% 0;

	.post-preview .post-content {
		background: $color-background-main;
	}

	.related-title {
		h1 {
			@extend %title3;
			font-family: $font-family-title;
			font-weight: $font-weight-light;
			margin-bottom: 1em;
		}
	}

	.row {
		display: flex;

		> div {
			display: flex;
			flex-direction: column;
		}

		.post-preview {
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;

			.post-content {
				flex: 1 1 auto;

				display: flex;
				flex-direction: column;

				.btns-row {
					margin-top: auto;

					a {
						justify-content: flex-end;
					}

					[class^=button] {
						&:hover {
							@extend %button_gold_hover;
						}
					}
				}
			}

			.overlay-link {
				&:hover {
					~ .post-content .btns-row [class^=button] {
						@extend %button_gold_hover;
					}
				}
			}
		}
	}

	@include at-most($screen-sm-max) {
		&.with-content {
			.row {
				flex-wrap: wrap;

				> div {
					order: 2;
					flex: 0 0 50%;

					&:last-child {
						order: 1;
						flex: 0 0 100%;
						width: 100%;
					}
				}
			}
		}

		.excerpt {
			display: none;
		}

		.posts-row {
			div:nth-child(n+3) {
				display: none;
			}
		}
	}

	@include at-most($screen-xs-max) {
		padding: 50/320 * 100% 0;

		&.with-content {
			padding-bottom: calc(50/320 * 100% - 30px);

			.row > div {
				flex: 0 0 100%;
				margin-bottom: 30px;

				> h1 {
					margin-bottom: 0;
				}
			}
		}

		.posts-row {
			display: block;
			
			> div + div {
				margin-top: 30px;
			}
		}
	}
}