.process {
	position: relative;
	border: 2px solid $color-accent-primary_light;
	margin: 50px 0;
	padding: 80px 0;

	.process-wrapper {
		.text-column {
			font-family: $font-family-title;
			font-weight: $font-weight-light;
			text-transform: uppercase;

			color: $color-accent-primary;
			text-align: center;
			padding: 0 50px; 

			.text {
				margin-top: 28px;
			}

			@include at-most($screen-md-max) {
				padding: 0 $grid-gutter-width/2;
			}

			@include at-most($screen-sm-max) {
				width: 50%;

				img {
					max-width: 218px;
				}
			}

			@include at-most($screen-xs-max) {
				width: 100%;
				margin-bottom: 30px;

				&:last-of-type() {
					margin-bottom: 0px;
				}
			}
		}

		@include at-most($screen-sm-max) {
			display: flex;
			flex-wrap: wrap;
		}
	}

	@include at-most($screen-xs-max) {
		margin: 30px 0;
		padding: 30px 0;
	}
}